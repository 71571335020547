<template>
  <component :is='componentFile' v-bind='componentProps'></component>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SingleComponentPage',
  data () {
    return {
      viewComponentName: 'HtmlPage',
      configurations: {},
    }
  },
  watch: {
    routeName: {
      handler: function (newVal, oldVal) {
        if (newVal === oldVal) return
        this.getDyanmicConfiguredPage(newVal).then(resp => {
          this.viewComponentName = resp[0].viewComponentName
          this.configurations    = resp[0].configurations
        })
      },
    },
  },
  computed: {
    componentFile () {
      return this.importComponent(this.viewComponentName)
    },
    componentProps () {
      return this.configurations
    },
    routeName () {
      return this.$route.name
    },
    directory () {
      return this.configurations.directory
    },
  },
  methods: {
    ...mapActions('events', [
      'getDyanmicConfiguredPage',
    ]),
    importComponent (viewComponentName) {
      if (viewComponentName !== 'div' && this.configurations.directory) {
        return () => import(`@/views/${this.configurations.directory}/${viewComponentName}.vue`)
      } else if (viewComponentName !== 'div' && !this.configurations.directory) {
        return () => import(`@/views/demo/${viewComponentName}.vue`)
      } else {
        return 
      }
    }
  },
  created () {
    this.getDyanmicConfiguredPage(this.$route.name).then(resp => {
      this.viewComponentName = resp[0].viewComponentName
      this.configurations    = resp[0].configurations
    })
  },
}
</script>

<style lang='scss' scoped>
  @media (min-width: 1024px) {
  }
</style>


